import React from 'react'
import '../../styles/components/product-card.scss'

function ProductCard({ image, mrp, isAvailable, weightOne, weightTwo,key }) {
  return (
    <div className='product-card-main' key={key}>
      <img src={require(`../../assets/${image}`)} alt={image} />
      <p>{`${weightOne} ( `}<span>&#x20b9;</span>{` ${weightTwo})`}</p>
      <p>MRP &#x20b9; {mrp}</p>
      <p className='available'>{isAvailable ? 'Available' : 'Not Available'}</p>
      <button>ADD TO CART</button>
    </div>
  )
}

export default ProductCard