import React from 'react'
import '../../styles/components/footer-componenet.scss'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Container } from 'react-bootstrap';

function FooterComponenet() {
  return (
<div className='footer-all'>
<Container >
      <Row>
        <Col xs={12} sm={6} md={4} lg={4}>
        <div className='footer-company'>
          <h2>Company</h2>
          <div className='link-main'>
          <a href="http://www.google.com">Why US?</a>
          <a href="http://www.google.com">Blog</a>
          <a href="http://www.google.com">About Us</a>
          </div>
        </div>         
        </Col>
        <Col xs={12} sm={6} md={4} lg={4}>
        <div className='footer-contact'>
          <h2>Contact Us</h2>
          <div className='link-main'>
          <a>Call: +91-63622-22740</a>
          <a>WhatsApp: +91-70222-37584</a>
          <a>admin@farmztofamiliez.com</a>
          </div>
          <p>OFFICE ADDRESS:</p>
          <p className='adderss'>Jalahalli Village, Bangalore, Karnataka - 560071</p>
        </div>
        </Col>
        <Col xs={12} sm={6} md={4} lg={4}>
        <div className='footer-usefullink'>
          <h2>Useful Links</h2>
          <div className='link-main'>
          <a>T&C</a>
          <a>FAQ</a>
          <a>Privacy Policy</a>
          <a>Shipping and delivery</a>
          <a>Cancellation and Refund</a>
          <a>To Get in touch with us</a>
          </div>
          <p>HAVE SECURITY CONCERN?</p>
          <a>Email @: security@farmztofamiliez.com</a>
        </div>
        </Col>
      </Row>
      <hr></hr>
      <Row>
        <Col><div className='Footer-botton'><p>© 2020 FarmzToFamiliez, Bangalore</p></div> </Col>
      </Row>
    </Container>

</div>      
  );
}

export default FooterComponenet