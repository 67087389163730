import React from 'react'
import '../../styles/components/explore-category.scss'
import { Container, Row } from 'react-bootstrap'

const productData = ['category_batter_chutney.webp', 'Category_Baked_Snacks.webp', 'f2f-cold-pressed-oils-and-ghee.webp']

function ExploreCategory() {
  return (
    <div className='explore-category-main'>
      <h2>Explore by Category</h2>

      <Container className='Category-container'>
        <Row className='Category-row'>
        <div className='explore-image-main'>
      {
        productData.map((e) => {
          return (
              <img src={require(`../../assets/${e}`)} />
            )
          })
        }
        </div>
        </Row>
      </Container>
    </div>
  )
}

export default ExploreCategory