import React from 'react'
import ProductCard from './product-card'
// import { Carousel, Card, Stack, Button } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";


const productData = [
  {
    image: 'Mixed_Nuts.jpg',
    weightOne: "500 gms",
    weightTwo: "0.36/ml",
    mrp: "59",
    isAvailable: true,
  },
  {
    image: 'Jaggerypowder.webp',
    weightOne: "500 gms",
    weightTwo: "0.36/ml",
    mrp: "59",
    isAvailable: true,
  },
  {
    image: 'Granolabars.webp',
    weightOne: "500 gms",
    weightTwo: "0.36/ml",
    mrp: "59",
    isAvailable: true,
  },
  {
    image: 'f2f-5l-groundnut-oil-can.webp',
    weightOne: "500 gms",
    weightTwo: "0.36/ml",
    mrp: "59",
    isAvailable: true,
  },
  {
    image: 'f2f-chakli-ragi.webp',
    weightOne: "500 gms",
    weightTwo: "0.36/ml",
    mrp: "59",
    isAvailable: true,
  },
  {
    image: 'f2f-oils-and-ghee.webp',
    weightOne: "500 gms",
    weightTwo: "0.36/ml",
    mrp: "59",
    isAvailable: true,
  },
]

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 3000, min: 2000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

function ProductCarousel() {
  return (
    <div style={{ width: "100%",margin:'10px',padding:"0% 5%" }}>
      <h1>Bestsellers</h1>
      <div >
      <Carousel 
      responsive={responsive}
      showDots={true}
      >
      {
        productData.map((elem,i) => {
          return (
            <ProductCard
              image={elem.image}
              weightOne={elem.weightOne}
              weightTwo={elem.weightTwo}
              isAvailable={elem.isAvailable}
              mrp={elem.mrp}
              key={i}
            />
          )
        })
      }
      </Carousel>
      </div>
    </div>
  )
}

export default ProductCarousel