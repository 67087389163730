import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import F2F_Traditional from '../../assets/F2F_Traditional THOKKUS & PASTES.webp'
import f2f_chutneys from '../../assets/f2f-chutneys.webp'
import f2f_ghee from '../../assets/f2f-ghee.webp'

function ImageCarousel() {
  return (
    <div style={{marginTop:"30px"}}>
    <Carousel data-bs-theme="dark">
      <Carousel.Item>
        <img
          className="d-block w-100 h-10"
          src={F2F_Traditional}
          alt="First slide"
        />
        {/* <Carousel.Caption>
          <h5>First slide label</h5>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption> */}
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="w-100"
          src={f2f_chutneys}
          alt="Second slide"
        />
        {/* <Carousel.Caption>
          <h5>Second slide label</h5>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption> */}
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={f2f_ghee}
          alt="Third slide"
        />
        {/* <Carousel.Caption>
          <h5>Third slide label</h5>
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p>
        </Carousel.Caption> */}
      </Carousel.Item>
    </Carousel>
    </div>
  )
}

export default ImageCarousel