import React from 'react'
import logo from '../../assets/logo.png'
import '../../styles/components/header.scss'
import { FaHome } from "react-icons/fa";
import { BsFillBucketFill } from "react-icons/bs";
import { FaWhatsapp } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import { FaRegEdit } from "react-icons/fa";
import { IoMdSearch } from "react-icons/io";

function Header() {
    return (
        <div className='header-component'>
            <div className='header-left-main'>
                <div className='image-main'>
                    <img src={logo} alt='logo' />
                </div>
                <div className='home-main'>
                    <FaHome className='icon' />
                    <p>Home</p>
                </div>
                <div className='home-main'>
                    <BsFillBucketFill className='icon' />
                    <p>Products</p>
                </div>
            </div>

            <div className='header-right-main'>
                {/* <div className='input-main'>
                    <input placeholder='Search bar...' />
                    <p><IoMdSearch className='icon' /></p>
                </div> */}
                <div className='home-main'>
                    <FaWhatsapp className='icon' />
                    <p>Help</p>
                </div>
                <div className='home-main'>
                    <FaUser className='icon' />
                    <p>Login</p>
                </div>
                <div className='home-main'>
                    <FaRegEdit className='icon' />
                    <p>Register</p>
                </div>
            </div>
        </div>
    )
}

export default Header