import React, { useState } from 'react'
import { useSpring, animated } from "react-spring";
import '../../styles/components/moving-text.scss'

function MovingText() {
  const [key, setKey] = useState(1);

  const scrolling = useSpring({
    from: { transform: "translate(60%,0)" },
    to: { transform: "translate(-60%,0)" },
    config: { duration: 40000 },
    reset: true,
    //reverse: key % 2 == 0,
    onRest: () => {
      setKey(key + 1);
    }
  });

  return (
     <div key={key} className='moving-text' >
      {/* <div style={{width:""}}> */}
      <animated.div style={scrolling}>{'Welcome to FARMZ TO FAMILIEZ  ---An All-Women Enterprise, passionate about promoting healthy living through wide range of products- including wholesome batters, millet-based treats, delectable snacks & Cold Pressed Oils ! Pure goodness delivered RIGHT TO YOUR DOORSTEP!!'}</animated.div>
      {/* </div> */}
    </div>
  )
}

export default MovingText