import React from 'react';
import { ImQuotesLeft } from "react-icons/im";
import { MdStarRate } from "react-icons/md";
import { ImQuotesRight } from "react-icons/im";
import '../../styles/components/testimonials-card.scss';

function TestimonialsCard({ message, name, companyName }) {
    return (
        <div className='testimonials-card-main'>
            <div className='card-main'>
                <div className='outer-card'>
                    <div className='icon-quote-left'><ImQuotesLeft className='icon'/></div>
                    <div className='star-main'>
                        <MdStarRate className='icon' />
                        <MdStarRate className='icon' />
                        <MdStarRate className='icon' />
                        <MdStarRate className='icon' />
                        <MdStarRate className='icon' />
                    </div>
                </div>
                <div className='seprate-text'>
                    <p className='message'>{message}</p>
                    <div className='footer-main'>
                        <p className='name'>{name}</p>
                        <p className='companyName'>{companyName}</p>
                        <p className='icon-qute-right'><ImQuotesRight /></p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TestimonialsCard;
