import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import '../../styles/components/recipes-carousel.scss'
import { Row } from 'react-bootstrap';

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 3000, min: 2000 },
    items: 3
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const productImage = ['sprouted-ragi-flour.webp', 'adirasam.webp', 'low-carb-batter.webp', 'malaysian-red-sambal.webp', 'stir-fried-millet-noodles.webp']

function RecipesCarousel() {
  return (
    <div className='recipes-main'>
      <h1>Recipes</h1>
      <Row>
      <Carousel className='Carousel-inner'
        responsive={responsive}
        showDots={true}
      >
        {
          productImage.map((e) => {
            return (
              <div className='image-main'>
                <img className='recipes-image' src={require(`../../assets/${e}`)} alt={e} />
              </div>
            )
          })
        }
      </Carousel>
      </Row>   
    </div>
  )
}

export default RecipesCarousel