import React from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import TestimonialsCard from './testimonials-card';
import '../../styles/components/testimonials.scss';

const testimonialsData = [
    {
        message: "My kids are big fan F2F Pancakes!",
        name: "K. Preetha",
        companyName: "PRESTIGE KENSINGTON GARDENS",
    },
    {
        message: "My kids are big fan F2F Pancakes!",
        name: "K. Preetha",
        companyName: "PRESTIGE KENSINGTON GARDENS",
    },
    {
        message: "My kids are big fan F2F Pancakes!",
        name: "K. Preetha",
        companyName: "PRESTIGE KENSINGTON GARDENS",
    },
];

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 3000, min: 2000 },
        items: 3
    },
    desktop: {
        breakpoint: { max: 1999, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1023, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 463, min: 0 },
        items: 1
    }
};

function Testimonials() {
    return (
        <div className='testimonials-main'>
            <h1>Testimonials</h1>
            <Carousel
                responsive={responsive}
                showDots={true}
            >
                {
                    testimonialsData.map((elem, i) => (
                        <TestimonialsCard
                            key={i}
                            message={elem.message}
                            companyName={elem.companyName}
                            name={elem.name}
                        />
                    ))
                }
            </Carousel>
        </div>
    );
}

export default Testimonials;
