import React from 'react'
import Header from '../components/page-components/header'
import ImageCarousel from '../components/page-components/image-carousel'
import MovingText from '../components/page-components/moving-text'
import ProductCarousel from '../components/page-components/product-carousel'
import ExploreCategory from '../components/page-components/explore-category'
import RecipesCarousel from '../components/page-components/recipes-carousel'
import Testimonials from '../components/page-components/testimonials'
import FooterComponenet from '../components/page-components/footer-componenet'

function Home() {
  return (
    <div>
        <Header/>
        <ImageCarousel/>
        <MovingText/>
        <ProductCarousel/>
        <ExploreCategory/>
        <RecipesCarousel/>
        <Testimonials/>
        <FooterComponenet/>
    </div>
  )
}

export default Home